/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 275px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 66.26506024096386%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAwT/2gAMAwEAAhADEAAAAcpqp0yqAz//xAAZEAACAwEAAAAAAAAAAAAAAAAAEQECEBL/2gAIAQEAAQUCLU5xoc5//8QAFhEAAwAAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/ARF//8QAGBEAAgMAAAAAAAAAAAAAAAAAARAREiH/2gAIAQIBAT8BNpxf/8QAFxABAAMAAAAAAAAAAAAAAAAAAQAgIf/aAAgBAQAGPwKGlf/EABoQAAICAwAAAAAAAAAAAAAAAAERABAxQVH/2gAIAQEAAT8hAbm0nygWF3//2gAMAwEAAgADAAAAEAQ//8QAFxEAAwEAAAAAAAAAAAAAAAAAARARIf/aAAgBAwEBPxAp1f/EABcRAQADAAAAAAAAAAAAAAAAAAEQESH/2gAIAQIBAT8QLssj/8QAGxAAAgMAAwAAAAAAAAAAAAAAAREAITEQQWH/2gAIAQEAAT8QWJoCHsRvyZk2CmV9LOP/2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Pennsylvania flag\"\n        title=\"Pennsylvania flag\"\n        src=\"/static/fe82a67e2f0b3186e7c75e37dc82ff2d/85f30/pennsylvania-flag.jpg\"\n        srcset=\"/static/fe82a67e2f0b3186e7c75e37dc82ff2d/4d5fb/pennsylvania-flag.jpg 166w,\n/static/fe82a67e2f0b3186e7c75e37dc82ff2d/85f30/pennsylvania-flag.jpg 275w\"\n        sizes=\"(max-width: 275px) 100vw, 275px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Pennsylvania State Flag"), "\n", React.createElement(_components.p, null, "In the early days, the region now known as Pennsylvania was, not surprisingly, inhabited by many Native American tribesmen.  At least five Native American tribes lived in the area which included the Delaware and Erie tribes.  In the 1600’s one could find a great deal of settlement in the area by the Europeans- mostly the Dutch and Swedish.  Later in 1664 control was lost to the British and subsequently became a British Colony. As it were King Charles II owed a large sum of money to a man named Sir William Penn -an English Admiral.  Sir William died in 1670 however leaving his son (also William Penn) to inherit the debt owed to his family.  So in 1681 King Charles II gave William Penn the area of land now known as Pennsylvania.  William started a colony which took his family’s name as well as “sylvania” meaning “forest” in Latin.  Pennsylvania is now the 6", React.createElement("sup", null, "th "), "most populated state with over 12 million people."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
